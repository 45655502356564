import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatnumber',
})
export class FormatNumber implements PipeTransform {
  transform(value: number): string {
    return value ? this.padLeft(value.toString(), '0', 3) : null;
  }
  padLeft(text: string, padChar: string, size: number): string {
    if (text.length < 4) {
      return (String(padChar).repeat(size) + text).substr(size * -1, size);
    } else {
      return text;
    }
  }
}
