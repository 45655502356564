import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";

import {Offer} from 'app/pages/nyhed-tilbud/interface';

@Component({
  selector: 'app-offer-preview-modal',
  templateUrl: './offer-preview-modal.component.html',
  styleUrls: ['./offer-preview-modal.component.scss'],
})
export class OfferPreviewModalComponent implements OnInit {
  @Input() offers: Offer[];
  imageLoaded = false;
  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
  }

  dismissModal(): void {
    this.modalCtrl.dismiss({});
  }


}
