import {Injectable} from '@angular/core';
import {Storage} from '@capacitor/storage';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

import {UserDetails} from 'app/shared/interface';

import {AuthStateService} from './auth-state.service';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private apiService: ApiService) {
  }

  async getUserDetail(): Promise<UserDetails> {
    const url = `get-user-detail.php?kardexId=${AuthStateService.authUser.getValue().code}`;
    return this.apiService.get(url).pipe(
      // eslint-disable-next-line
      map(res => res['userDetails'])
    ).toPromise();
  }

  async logout(): Promise<void> {
    let language = await Storage.get({key: 'lang'});
    await Storage.clear();
    return Storage.set({key: 'lang', value: language.value}); // Maintain Current Language
  }

  removeUserDetails(): Promise<void> {
    return Storage.clear();
  }

}
