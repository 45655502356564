export const da_locale = {
  labelReset: "Nulstil",
  labelDefault: "Standard",
  labelAuto: "Auto",
  labelNone: "Ingen",
  labelEdit: "Redigere",
  labelClose: "Tæt",
  labelSizeExtraSmall: "Ekstra lille",
  labelSizeSmall: "Lille",
  labelSizeMediumSmall: "Medium lille",
  labelSizeMedium: "Medium",
  labelSizeMediumLarge: "Mellemstor",
  labelSizeLarge: "Stor",
  labelSizeExtraLarge: "Ekstra stor",
  labelButtonRevert: "Vende tilbage",
  labelButtonCancel: "Afbestille",
  labelButtonUndo: "Fortryd",
  labelButtonRedo: "Gentag igen",
  labelButtonExport: "GEM",
  cropLabel: "Afgrøde",
  cropLabelButtonRecenter: "Recenter",
  cropLabelButtonRotateLeft: "Drej til venstre",
  cropLabelButtonRotateRight: "Drej til højre",
  cropLabelButtonFlipHorizontal: "Vend vandret",
  cropLabelButtonFlipVertical: "Vend lodret",
  cropLabelSelectPreset: "Afgrødeform",
  cropLabelCropBoundary: "Afgrænsning",
  cropLabelCropBoundaryEdge: "Kant af billedet",
  cropLabelCropBoundaryNone: "Ingen",
  cropLabelTabRotation: "Rotation",
  cropLabelTabZoom: "Zoom",
  statusLabelButtonClose: "Tæt",
  stickerLabel: "Klistermærke",
  shapeLabelButtonSelectSticker: "Vælg et billede",
  shapeTitleButtonFlipHorizontal: "Vend Vandret",
  shapeTitleButtonFlipVertical: "Vend Lodret",
  shapeTitleButtonRemove: "Fjerne",
  shapeTitleButtonDuplicate: "Duplikere",
  shapeTitleButtonMoveToFront: "Bevæge sig fremad",
  shapeLabelInputText: "Rediger tekst",
  shapeLabelInputCancel: "Afbestille",
  shapeLabelInputConfirm: "Bekræfte",
  shapeLabelStrokeNone: "Ingen ydre foring",
  shapeLabelFontStyleNormal: "Normal",
  shapeLabelFontStyleBold: "Fremhævet",
  shapeLabelFontStyleItalic: "Kursiv",
  shapeLabelFontStyleItalicBold: "Kursiv fed",
  shapeTitleBackgroundColor: "Fyld farve",
  shapeTitleFontFamily: "Skrifttype",
  shapeTitleFontSize: "Skriftstørrelse",
  shapeTitleFontStyle: "Font stil",
  shapeTitleLineHeight: "Førende",
  shapeTitleLineStart: "Start",
  shapeTitleLineEnd: "Ende",
  shapeTitleStrokeWidth: "Linjebredde",
  shapeTitleStrokeColor: "Stregfarve",
  shapeTitleLineDecorationBar: "Bar",
  shapeTitleLineDecorationCircle: "Cirkel",
  shapeTitleLineDecorationSquare: "Firkant",
  shapeTitleLineDecorationArrow: "Pil",
  shapeTitleLineDecorationCircleSolid: "Cirkel solid",
  shapeTitleLineDecorationSquareSolid: "Firkantet fast",
  shapeTitleLineDecorationArrowSolid: "Pilen solid",
  shapeTitleColorTransparent: "Gennemsigtig",
  shapeTitleColorWhite: "Hvid",
  shapeTitleColorSilver: "Sølv",
  shapeTitleColorGray: "Grå",
  shapeTitleColorBlack: "Sort",
  shapeTitleColorNavy: "Marineblå",
  shapeTitleColorBlue: "Blå",
  shapeTitleColorAqua: "Vand",
  shapeTitleColorTeal: "Teal",
  shapeTitleColorOlive: "Oliven",
  shapeTitleColorGreen: "Grøn",
  shapeTitleColorYellow: "Gul",
  shapeTitleColorOrange: "Orange",
  shapeTitleColorRed: "Rød",
  shapeTitleColorMaroon: "Maroon",
  shapeTitleColorFuchsia: "Fuchsia",
  shapeTitleColorPurple: "Purple",
  shapeTitleTextColor: "Skrift farve",
  shapeTitleTextAlign: "Tekstjustering",
  shapeTitleTextAlignLeft: "Venstrejustering af tekst",
  shapeTitleTextAlignCenter: "Centerjustering af tekst",
  shapeTitleTextAlignRight: "Højrejuster tekst",
  shapeLabelToolSharpie: "Sharpie",
  shapeLabelToolEraser: "Viskelæder",
  shapeLabelToolRectangle: "Ramme",
  shapeLabelToolEllipse: "Ellipse",
  shapeLabelToolArrow: "Pil",
  shapeLabelToolLine: "Linie",
  shapeLabelToolText: "Tekst",
  shapeLabelToolPreset: "Prismærke",
  statusLabelProcessImage: (state) => {
    if (!state || !state.task)
      return undefined;
    if (state.task === 'store') {
      if (state.error)
        return 'Fejl ved upload af billede';
      return 'Uploader billede';
    }
    if (state.error)
      return 'Fejl ved behandling af billede';
    return 'Behandler billede…';
  },
  statusLabelLoadImage: (state) => {
    if (!state || !state.task)
      return 'Venter på billede';
    if (state.error)
      return 'Fejl ved indlæsning af billede';
    if (state.task === 'blob-to-bitmap')
      return 'Opretter forhåndsvisning…';
    return 'Indlæser billede…';
  }
}
