export const ERROR_MESSAGES = {
  system: {
    '-404': '_something_went_wrong_try_again_',
    // '-83': '_session_expired_',
    '-200':'_empty_or_invalid_folder_id_',
    '-800':'_empty_data_',
    '-801':'_invalid_phone_',
    '-802':'_invalid_jatak_offer_id_',
    '-804':'_jatak_server_error_',
    '-805':'_duplicate_variant_keyword_',
    '-1':'_missing_params_',
    '-2':'_invalid_params_',
    '-4':'_invalid_datetime_',
    '-5':'_invalid_promo_id_',
    '-10':'_invalid_price_error_',
    '-99':'_internal_error_',
    '-78':'_access_denied_',
    '-79':'_empty_username_',
    '-80':'_empty_password_',
    '-81':'_user_not_exist_',
    '-82':'_password_wrong_',
    '-83':'_invalid_token_',
    '-84':'_expired_token_',
    '-85':'_empty_access_token_',
    '-86':'_empty_promo_',
    '-122':'_empty_promo_id_',
    '-74':'_invalid_fb_connected_',
    '-73':'_empty_fb_connected_',
    '-72':'_empty_solution_id_',
    '-71':'_wrong_solution_id_',
    '-70':'_empty_standard_text_',
    '-69':'_empty_content_',
    '-65':'_internal_server_error_',
    '-64':'_invalid_mobiweb_id_',
    '-61':'_service_bus_error_',
    '-123':'_no_available_solution_for_user_',
    '-124':'_empty_club_id_',
    '-135':'_invalid_club_member_',
    '-125':'_already_connected_',
  },
  login: {
    '-81': '_user_not_exist_',
    '-82':  '_password_wrong_'
  },
  promo: {
    '-10': '_invalid_price_',
    '-75': '_invalid_kardex_id_',
    '-400': '_something_went_wrong_try_again_',
  },
  sms: {
    '-400': '_something_went_wrong_try_again_',
    '_45':  '_This_phone_number_is_already_in_your_club'
  },
  fbcomment: {
    '-400': '_something_went_wrong_try_again_',
  },
  user: {
    '-74': '_invalid_kardex_id_',
  },
  image: {
    '-400': '_something_went_wrong_try_again_',
  },
};
