import {createAnimation} from '@ionic/angular';
import {Animation} from '@ionic/core';


export const navigationAnimation = (baseEl: HTMLElement, opts?: any): Animation => {

  if (opts.direction === 'forward') {

    const enter = createAnimation()
      .addElement(opts.enteringEl)
      .easing('cubic-bezier(.36,.66,.04,1)')
      .duration(500)
      .fromTo('opacity', 0.9, 1)
      .fromTo('transform','translateX(100%)', 'translateX(0%)');
    return enter;

  } else {

    const enter = createAnimation()
      .addElement(opts.enteringEl)
      .easing('cubic-bezier(.36,.66,.04,1)')
      .duration(500)
      .fromTo('opacity', 0.9, 1);

    const leave = createAnimation()
      .addElement(opts.leavingEl)
      .easing('cubic-bezier(.36,.66,.04,1)')
      .duration(500)
      .fromTo('opacity', 1, 0.5)
      .fromTo('transform','translateX(0%)', 'translateX(100%)');

    return createAnimation().addAnimation([enter, leave]);
  }
};
