import { Component, OnInit } from '@angular/core';
import {PopoverController} from "@ionic/angular";

@Component({
  selector: 'app-language-popover',
  templateUrl: './language-popover.component.html',
  styleUrls: ['./language-popover.component.scss'],
})
export class LanguagePopoverComponent implements OnInit {
 // languageList = ['da', 'en', 'fi', 'nl', 'sv'];
  languageList = ['da', 'en'];
  currentLanguage = 'da';

  constructor(private popoverCtrl: PopoverController) { }

  ngOnInit() {}

  onSelect(dest) {
    if (!dest) {
      return;
    }
    this.currentLanguage = dest;
    this.dismissPopover(dest);
  }

  private async dismissPopover(selection: string) {
    await this.popoverCtrl.dismiss(selection);
  }

}
