import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from 'app/core/guards';
import {MainComponent, NoPageComponent} from 'app/shared/components';

const routes: Routes  = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'tabs',
        loadChildren: () => import('app/tabs/tabs.module').then(m => m.TabsPageModule),
        canActivate:[AuthGuard],
        canActivateChild:[AuthGuard]
      },
      {
        path: 'nyhed-tilbud',
        loadChildren: () => import('app/pages/nyhed-tilbud/nyhed-tilbud.module').then(m => m.NyhedTilbudModule),
        canActivate:[AuthGuard],
        canActivateChild:[AuthGuard]
      },
      {
        path: 'sms',
        loadChildren: () => import('app/pages/sms/sms.module').then(m => m.SmsModule),
        canActivate:[AuthGuard],
        canActivateChild:[AuthGuard]
      },
      {
        path: 'personlig-hilsen',
        loadChildren: () => import('app/pages/sms-klub/sms-klub.module').then(m => m.SmsKlubModule),
        canActivate:[AuthGuard],
        canActivateChild:[AuthGuard]
      },
      {
        path: 'booklet',
        loadChildren: () => import('app/pages/booklet/booklet.module').then(m => m.BookletModule),
        canActivate:[AuthGuard],
        canActivateChild:[AuthGuard]
      },
      {
        path: '',
        redirectTo: 'tabs',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'login',
    loadChildren: () => import('app/pages/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: '**',
    component: NoPageComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
