import { Injectable } from '@angular/core';
import {
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { AuthStateService } from './auth-state.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class RequestInterceptorService implements HttpInterceptor {

  constructor(private router: Router,
    private userService: UserService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.handle(req, next);
  }

  private setRequestHeaders(request: HttpRequest<any>) {
    const headerObject = {};
    if (request.method === 'POST' && !(request.body instanceof FormData)) {
      headerObject['Content-type'] = 'application/x-www-form-urlencoded';
    }
    if (request.method === 'GET') {
      headerObject['Content-type'] = 'application/json';
    }
    if (request.url.indexOf(environment.apiUrl) !== -1 ||
      request.url.indexOf(environment.jatakUrl) !== -1) {
      // *** If request are made after authentication add Token and KardexId on respective headers *** //
      const isActive = AuthStateService.isTokenActive();
      if (isActive) {
        headerObject['X-User-Id'] = AuthStateService.authUser.value.code;
        headerObject['Authorization'] = `Bearer ${AuthStateService.authUser.value.token}`;
      }
    }
    const headers = new HttpHeaders(headerObject);
    return request.clone({ headers });
  }

  private handle(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(this.setRequestHeaders(req))
      .pipe(
        map((event: HttpEvent<any>) => event),
        catchError((httpError: HttpErrorResponse) => {
          const isTokenInvalid = httpError.error.code === -83 || httpError.error.code === -84 || httpError.error.code === -85;
          if (httpError.status === 401 && isTokenInvalid) {
            this.userService.removeUserDetails().then();
            this.router.navigate(['/']).then();
            return throwError(httpError.error);
          }
          if (!environment.production) {
            console.log(httpError);
          }
          return throwError(httpError.error);
        })
      );
  }
}
