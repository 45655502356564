import {AfterViewInit, Component} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

import {AuthStateService} from 'app/core/services';
import {filter} from 'rxjs/operators';
import {Platform} from '@ionic/angular';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements AfterViewInit {
  isAuthenticated = false;
  constructor(private router: Router) {
  }

  ngAfterViewInit(): void {

    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe(
      e => {
        this.isAuthenticated = AuthStateService.isTokenActive();
      });
  }

}
