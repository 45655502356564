import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe(
{
    name: 'dyTranslate'
}
)
export class DynamicTranslate implements PipeTransform {
    constructor(private translate: TranslateService) {}
    transform(text: string): string {
        if (!text) return;
      const toBeTranslated = text.split('::');
      return this.translate.instant(toBeTranslated[0]) + (toBeTranslated[1] ? toBeTranslated[1] : '' );
    }

  }
