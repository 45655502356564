import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FacebookEventsService {


  // Connect Facebook
  private _connectFacebook = new Subject<boolean>();
  $connectFacebook = this._connectFacebook.asObservable();

  // Disonnect Facebook
  private _disconnectFacebook = new Subject<boolean>();
  $disconnectFacebook = this._disconnectFacebook.asObservable();

  // Facebook Connected
  private _fbConnected = new Subject<boolean>();
  $fbConnected = this._fbConnected.asObservable();


  // Show facebook Login Popup to connect
  connectFacebook() {
    this._connectFacebook.next(true);
  }

  // Show confirmation dialog to disconnect Facebook
  disconnectFacebook() {
    this._disconnectFacebook.next(true);
  }


  facebookConnected(state: boolean) {
    this._fbConnected.next(state);
  }
}
