import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class SMSTabState {
  private _routeState = new BehaviorSubject<string>('find');
  $routeState = this._routeState.asObservable();

  setRouteState(state: string) {
    this._routeState.next(state)
  }
}

@Injectable({
  providedIn: 'root'
})
export class MedlemEvents {
  private _personalMessageSaved = new Subject<boolean>();
  $personalMessageSaved = this._personalMessageSaved.asObservable();

  savePersonalMessage(state: boolean) {
    this._personalMessageSaved.next(state)
  }
}

@Injectable({
  providedIn: 'root'
})
export class SMSEvents {
  private _SMSSaved = new Subject<boolean>();
  $SMSSaved = this._SMSSaved.asObservable();

  smsSaved(state: boolean) {
    this._SMSSaved.next(state);
  }
}
