import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class FileReaderService {
    static readFile(file, type: string) {
        let readerObj;
        return Observable.create(observer => {
            if (type === 'unsupported') {
                observer.error(file);
                observer.complete();
            } else {
                const reader = new FileReader();
                reader.onload = (e: any) => {
                    readerObj = {
                        filesource: e.target.result,
                        ready: true
                    };
                    observer.next(readerObj);
                    observer.complete();
                };
                if (type === 'application/pdf') {
                    reader.readAsArrayBuffer(file);
                } else {
                    reader.readAsDataURL(file);
                }
            }
        });
    }
}
