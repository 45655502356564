import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
import {LangService} from 'app/core/services';

@Pipe({
  name: 'quickdate'
})
export class DateFormatPipe implements PipeTransform {
  lang: string;

  transform(date: string) {
    this.getDate();
    if (this.lang === 'en') {
      moment.locale('en');
      return moment(date).format('DD. MMMM YYYY, [at] HH:mm');
    }
    moment.locale('da');
    return moment(date).format('LL, [kl.] HH:mm');
  }

  getDate() {
    let lang = localStorage.getItem('CapacitorStorage.lang')
    this.lang = lang
    LangService.getLang().then(
      lang => this.lang = lang
    )
  }
}

@Pipe({
  name: 'simpledateonly'
})
export class SimpleDateFormatPipe implements PipeTransform {
  transform(date: string): any {
    moment.locale('da');
    return moment(date).format('ll');
  }
}

@Pipe({
  name: 'datetostring'
})
export class DateToString implements PipeTransform {
  transform(date: Date): string {
    return moment(date).format('YYYY-MM-DD HH:mm');
  }
}
