import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'numberpad'
})
export class NumberPad implements PipeTransform {
  transform(value: number, language?: string): string {
    return value.toLocaleString(language, {minimumFractionDigits: 2, maximumFractionDigits: 2});
  }
}
