import {Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter} from '@angular/core';
import {ActionSheetController, Platform} from '@ionic/angular';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import {TranslateService} from "@ngx-translate/core";
import {take} from 'rxjs/operators';

import {ErrorHandlerService, ImageService} from 'app/core/services';
import {LangService} from "app/core/services";

@Component({
  selector: 'app-image-handler',
  templateUrl: './image-handler.component.html',
  styleUrls: ['./image-handler.component.scss'],
})
export class ImageHandlerComponent implements OnInit {
  @ViewChild('fileinput') fileInput: ElementRef;
  @Input() hasImage: boolean;
  @Input() editImage: boolean = true;
  @Input() isArchived: boolean = true;
  @Input() usingTemplate: boolean = true;
  @Input() imageUrl;

  @Output() imageUploadedUrl= new EventEmitter<string>();

  isProcessing = false;
  imageUploaded = false;
  imageLoaded = false;
  imageChangedEvent;
  aspectRatio = 1;
  editorOptions;
  showEditor = false;
  imgUrl: string;
  currentLanguage: string;
  constructor(private actionSheetCtrl: ActionSheetController,
              private imageService: ImageService,
              private errorHandlerService: ErrorHandlerService,
              private platform: Platform,
              private androidPermissions: AndroidPermissions,
              private _translate: TranslateService) { }


  async ngOnInit() {

    if(this.usingTemplate){
      this.editImage = true
      this.isArchived = false
    }
    // for app
    // "hybrid" will detect Cordova or Capacitor
    // if (this.platform.is('android')) {
    //   this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.CAMERA).then(
    //     result => {
    //       if (!result.hasPermission) {
    //         this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.CAMERA,this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE])
    //       }
    //     })
    //     .catch((err) => this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.CAMERA,this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE]));
    // }
    const currentLanguage = await LangService.getLang();
    this.currentLanguage = currentLanguage;
  }

  chooseFiles() {
    this.fileInput.nativeElement.click();
  }

  async addImage(useCamera = false, alert) {
    const image = await this.imageService.addNewToGallery(useCamera);
    this.imageChangedEvent = await (await fetch(image.dataUrl)).blob();
    this.showEditor = true;
    alert.dismiss();
  }

  async onUpload() {
    const actionSheet = await this.actionSheetCtrl.create({
      cssClass: 'actions',
      buttons: [
        {
          text: this._translate.instant('_choose_files_'),
          icon: 'folder-outline',
          handler: () => {
            this.addImage(false, actionSheet);
          }
        },
        {
          text: this._translate.instant('_take_photo_'),
          icon: 'camera-outline',
          handler: () => {
            this.addImage(true, actionSheet);
          }
        }, {
          text: this._translate.instant('_cancel_'),
          icon: 'close-outline',
          role: 'cancel'
        }
      ]
    });
    await actionSheet.present();
  }

  fileChangeEvent(event) {
    const file = event.target.files[0];
    event = null;
    this.imageChangedEvent = file;
    this.showEditor = true;
  }

  onImageLoaded() {
    this.imageLoaded = true;
  }

  imageProcessed(event: any) {
    this.showEditor = false;
      const fileFormat = event.type.split('/')[1];
      this.upload(event, fileFormat);
  }

  closeEditor() {
    this.showEditor = false;
  }

  editorLoaded(): void {}


  private upload(fileData: any, format: any) {
    if (!this.isProcessing) {
      this.isProcessing = true;
      this.imageLoaded = false;
      this.imageService.imageUpload(fileData, format).pipe(
        take(1)
      ).subscribe(
        (imgUrl: string) => {
          this.imgUrl = imgUrl;
          this.imageUploaded = true;
          this.isProcessing = false;
          this.imageUploadedUrl.emit(imgUrl);
        },
        err => this.handleError(err)
      );
    }
  }


  private handleError(error): void {
    this.errorHandlerService.handleError(error || {code: -400}, 'image');
    this.isProcessing = false;
  }

}
