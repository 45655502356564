import { InjectionToken } from '@angular/core';


export const defaultErrors = {

  required: (error) => '_please_fill_field_',
  minlength: ({ requiredLength, actualLength }) => `Expect ${requiredLength} but got ${actualLength}`,
  invalidPhone: () => '_invalid_phone_number_',
  invalidPrice: () => '_invalid_price_',
  invalidPrices:()=>'_price_only_allow_number_and _dot_',
  invalidItemNumber:()=>'_number_of_item_Sale_should_be_zero_',
  duplicate: ({ keyword }) => '_duplicate_keyword_' + '::' + keyword

};
export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: () => defaultErrors
});
