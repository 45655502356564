import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'excerpt'
})
export class Excerpt implements PipeTransform {

  transform(text: string): string {
    return text.split('\n')[0];
  }

}

@Pipe({
  name: "removelink"
})
export class RemoveLink implements PipeTransform {
  transform(value: string): string {
    const linkremovedtext = value.split('#RLINK#').length >= 2 ? value.split('#RLINK#')[0] : value.split('#LINK#')[0]
    return linkremovedtext.slice(0, 40);
  }
}
