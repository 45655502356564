import {Component, OnInit} from '@angular/core';
import {environment} from 'environments/environment';
import {AuthStateService} from './core/services';
import {filter} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';



@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  isAuthenticated = false;
  constructor(private router: Router) {
    AuthStateService.loadToken();
    this.isAuthenticated = AuthStateService.isTokenActive();
  }

  ngOnInit() {
    console.log('Version', environment.version);
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe(
      e => {
        this.isAuthenticated = AuthStateService.isTokenActive();
      });
  }
}
