import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {AuthState} from 'app/shared/interface';
import {TokenService} from './token.service';



@Injectable({
  providedIn: 'root'
})

export class AuthStateService {
  static authUser = new BehaviorSubject<AuthState>({token: null, code: null, expiration: ''});

  constructor() {
  }

  static isTokenActive(tokenof?: string): boolean {
    const currentTime = Math.floor(Date.now() / 1000);
/*    if (tokenof === 'facebook') {
      const fbAccess = await Storage.get({key: '_fbAccess_c'});
      const fbExpiration = await Storage.get({key: '_fbExpiration_c'});
      return (
        fbAccess &&
        fbExpiration &&
        fbAccess.value !== '' &&
        fbExpiration.value !== '' &&
        (parseInt(fbExpiration.value, 10) - (60 * 60 * 24)) > currentTime
      );
    };*/
    const coopToken = AuthStateService.authUser.value.token;
    const coopexpiration = AuthStateService.authUser.value.expiration;
    return (
      coopToken &&
      coopexpiration &&
      coopexpiration !== '' &&
      coopToken !== '' &&

      (parseInt(coopexpiration, 10) - (60 * 60 * 24)) > currentTime
    );
  }


  static async loadToken() {
    const loginDetails = await TokenService.getLoginDetails();
    const userCode = await TokenService.getUserCode();
    if (loginDetails) {
      AuthStateService.authUser.next({
        token: loginDetails?.access_token,
        code: userCode,
        expiration: loginDetails?.expiration
      });
    }
  }

  static onLogout() {
    AuthStateService.authUser.next({token: null, code: null, expiration: ''});
  }



}
