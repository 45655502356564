import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input() color = 'primary';
  @Input() buttonType = 'button';
  @Input() disabled = false;
  @Input() expand = 'block';
  @Input() fill = 'solid';
  @Input() size = 'default';
  @Input() shape = undefined;
  @Input() strong = false;
  @Input() type = 'button';
  @Input() isLoading = false;
  @Input() spinnerColor = 'light';
  @Input() icon;
  @Input() iconposition = 'start';

  constructor() {
  }

  ngOnInit() {
  }

}
