import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-skeleton',
  templateUrl: './list-skeleton.component.html',
  styleUrls: ['./list-skeleton.component.scss'],
})
export class ListSkeletonComponent implements OnInit {
  items = [0];
  constructor() { }

  ngOnInit() {}

}
