import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Router} from '@angular/router';
import {ModalController} from '@ionic/angular';
import {TranslateService} from "@ngx-translate/core";
import {mergeMap, takeWhile} from 'rxjs/operators';

import {environment} from 'environments/environment';
import {
    ErrorHandlerService,
    FacebookService,
    ToastService,
    TokenService,
    UserService,
    FacebookEventsService, LangService
} from 'app/core/services';
import {FaceBookPage} from 'app/shared/interface';

@Component({
    selector: 'app-facebook-connect-modal',
    templateUrl: './facebook-connect-modal.component.html',
    styleUrls: ['./facebook-connect-modal.component.scss'],
})
export class FacebookConnectModalComponent implements OnInit, OnDestroy {
    title;
    isConnected = false;
    showLogin = false;
    pages: FaceBookPage[];
    pageResponse = true;
    nextList: string;
    prevList: string;
    fbloginurl: string = null;
    child: Window;
    imageLoaded = false;
    private subscriptionState = true;

    constructor(private modalCtrl: ModalController,
                private renderer: Renderer2,
                private facebookService: FacebookService,
                private errorHandlerService: ErrorHandlerService,
                private userService: UserService,
                private toastService: ToastService,
                private facebookEvents: FacebookEventsService,
                private router: Router,
                private _translate: TranslateService) {
    }

    ngOnInit() {
        this.title = this._translate.instant('_click_on_page_to_connect_');
        this.child = window;

        this.renderer.listen('window', 'message', event => {
            this.receiveFacebookToken(event);
        });

        // Close FB Login on any route changes
        this.router.events
            .pipe(takeWhile(() => this.subscriptionState))
            .subscribe(() => this.closeFbLogin());

        // Listen to any event asking to connect FB
        // Fetch FB login Url
        this.facebookEvents.$connectFacebook
            .pipe(
                takeWhile(() => this.subscriptionState),
                mergeMap(state => this.setfbUrl(state))
            )
            .subscribe(
                async loginurl => {
                    // Check for FB Connection Status
                    this.isConnected = (await TokenService.getUserDetail()).facebook.connected;
                    this.showLogin = true;
                    this.fbloginurl = loginurl;
                },
                error => this.errorHandlerService.handleError(error)
            );
        this.facebookEvents.connectFacebook();
    }

    ngOnDestroy() {
        this.subscriptionState = false;
    }

    dismissModal(): void {
        this.modalCtrl.dismiss({});
    }

    pageLogin() {
        // IF Facebook Dialog Url is fetched open it in a child poppup
        if (this.fbloginurl) {
            
            this.child.open(this.fbloginurl, 'Facebook Login', 'width=600, height=420');
        }
    }

    closeFbLogin() {
        this.showLogin = false;
    }

    selectPage(page: FaceBookPage) {
        this.closeFbLogin();
        this.facebookService.extendToken(page.access_token)
            .then(token => this.saveFBDetails(page, token))
            .then(() => this.userService.getUserDetail())
            .then((userDetail) => {
                TokenService.setUserDetail(userDetail);
                this.facebookEvents.facebookConnected(true);
                this.toastService.toast(this.title = this._translate.instant('_connected_to_facebook_now_share_'), 'success').then(
                    () => this.dismissModal());
            }).catch(error => this.handleError(error));
    }

    // Facebook returns 10 items per request
    // If there is more than 10 we use this to move through the pagination
    loadMorePages(type: string): void {
        const url = type === 'prev' ? this.prevList : this.nextList;
        this.facebookService.getPagination(url)
            .then(fbresponse => this.loadPageList(fbresponse))
            .catch(err => this.errorHandlerService.handleError(err));
    }

    private async saveFBDetails(page: FaceBookPage, token) {
        return this.facebookService.updateFbData({
            connected: true,
            id: page.id,
            name: page.name,
            profileImage: page.picture.data.url,
            accessToken: token
        }).catch((err) => this.handleConnectError(err));
    }

    private setfbUrl(state: boolean): Promise<string | any> {
        return this.facebookService.getLoginUrl();
    }

    private receiveFacebookToken(e: MessageEvent) {
        if (e.origin !== environment.mainUrl || e.data.source !== 'FB_LOGIN') {
            return;
        }
        this.facebookService.getPageList(e.data.token)
            .then(
                fbResponse => {
                    // if there is no pages
                    if (fbResponse['data'].length === 0) {
                        this.pageResponse = false;
                        this.title = this._translate.instant('_no_page_linked_');
                    } else {
                        this.title = this._translate.instant('_click_on_page_to_connect_');
                        this.pageResponse = true;
                        this.loadPageList(fbResponse);
                    }
                }
            ).catch(err => this.errorHandlerService.handleError(err));
    }

    private loadPageList(fbResponse) {
        this.pages = fbResponse['data'];
        this.nextList = fbResponse['paging']['next'];
        this.prevList = fbResponse['paging']['previous'];
    }

    private handleConnectError(err) {
        this.dismissModal();
        this.handleError(err);
        return Promise.reject();
    }

    private async handleError(error) {
        const currentLang = await LangService.getCurrentLang();
        const desc = currentLang === 'da' ? 'description' : 'description_en';
        if (error && error['already_connected']) {
            this.toastService.toast(error[desc], 'error').then()
            return
        }
        // TODO: Fix multiple account conection error
        if (error) {
            this.errorHandlerService.handleError(error);
        }
    }
}
