import {animate, style, transition, trigger, query, stagger, animateChild} from '@angular/animations';
export const listItemFade = trigger('items', [
  transition(':enter', [
    style({transform: 'scale(0.8)', opacity: 0}),
    animate('300ms cubic-bezier(.8, -0.6, 0.2, 1.5)',
      style({transform: 'scale(1)', opacity: 1}))
  ]),
  transition(':leave', [
    style({transform: 'scale(1)', opacity: 1, height: '*'}),
    animate('300ms cubic-bezier(.8, -0.6, 0.2, 1.5)',
      style({
        transform: 'scale(0.8)', opacity: 0,
        height: '0px', margin: '0px'
      }))
  ])
]);
export const listAnimate = trigger('list', [
  transition(':enter', [
    query('@items', stagger(200, animateChild()), { optional: true })
  ])
]);
