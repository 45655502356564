import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import {Observable, of} from 'rxjs';

import {ERROR_MESSAGES} from 'app/core/data/error-list.data';
import {Error} from 'app/shared/interface';
import {environment} from 'environments/environment';
import {ToastService} from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(private router: Router,
              private toastService: ToastService,
              private _translate: TranslateService
  ) {}

  static error(errorMessage, error?) {
      if (!environment.production && error) {
        console.log(error);
      }
  }

    handleError(error: Error, errorOf?: string): void {
      // errorOf = errorOf || 'system';
      errorOf = 'system';
      if (!environment.production) {
      console.log(error, errorOf);
    }
    const errorMessage = (error.code && ERROR_MESSAGES[errorOf][error.code.toString()]) ?
      this._translate.instant(ERROR_MESSAGES[errorOf][error.code.toString()])
      : this._translate.instant('_something_went_wrong_try_again_');
      this.toastService.toast(errorMessage, 'error').then();

  }

    routeResolverError(error): Observable<any> {
      this.handleError(error);
      this.router.navigate(['/404']).then();
      return of(error);
    }
}
